import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { createContext, useContext, useState } from "react";

const AlertContext = createContext();
const AUTO_DISMISS = 5000;

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

export const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [title, setTitle] = useState("");

  const showAlert = ({ ...props }) => {
    setMessage(props?.message || "");
    setSeverity(props?.severity || "success");
    setTitle(props?.title);
    setOpen(true);

    setTimeout(() => {
      hideAlert();
    }, AUTO_DISMISS);
  };

  const hideAlert = () => {
    setOpen(false);
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}

      <Snackbar
        open={open}
        autoHideDuration={AUTO_DISMISS}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={hideAlert}
          severity={severity}
          // variant={variant}
          sx={{ width: "100%" }}
          // action={action}
          // color={color}
        >
          {title && <AlertTitle>{title}</AlertTitle>}

          {message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};
